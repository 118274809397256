<template>
  <div :class="colClass" v-if="checkShowConditioned && accessControl" >
    <label v-if="f.field_type == 'date'" class="vs-input--label">{{fieldName}}</label>

    <!-- input group -->
    <template v-if="useInput && useInputGroup">
      <label for="" class="vs-input--label">{{fieldName}}</label>
      <vx-input-group>
        <vs-input
          class="w-full"
          :type="fieldType"
          v-model.lazy="dataContainer[fname]"
          :name="fname"
          v-validate="validationRules"
          :danger="hasError"
          :danger-text="errorText"
          :success="isSuccess"
          icon-pack="feather"
          v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
          @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
          :icon="inputIcon"
          :description-text="fieldDescription"
          :placeholder="fieldPlaceholder(f)"
          :disabled="checkDisabledConditioned"          
        />
        <template slot="append">
        <div class="append-text border-success" style="height:40px">
          <span class="text-success"></span>
        </div>
      </template>
      </vx-input-group>
    </template>
    <!-- /input group -->
   
   <!-- Campos simples -->
    <vs-input
      v-if="useInput && !useInputGroup"
      class="w-full"
      :label="fieldName"
      :type="fieldType"
      v-model.lazy="dataContainer[fname]"
      :name="fname"
      v-validate="validationRules"
      :danger="hasError"
      :danger-text="errorText"
      :min="0"
      :success="isSuccess"
      autocomplete="off"
      icon-pack="feather"
      v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
      @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
      :icon="inputIcon"
      :description-text="fieldDescription"
      :placeholder="placeholder"
      :disabled="checkDisabledConditioned"
    />

    <vs-input
      v-else-if="useCurrency"
      ref="currency"
      class="w-full"
      :label="fieldName"
      type="text"
      v-model.lazy="aliasCurrency"
      v-money="money"
      :name="fname"
      v-validate="validationRules"
      :danger="hasError"
      :danger-text="errorText"
      :min="0"
      :success="isSuccess"
      autocomplete="off"
      icon-pack="feather"
      v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
      @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
      :icon="inputIcon"
      :description-text="fieldDescription"
      :placeholder="placeholder"
      :disabled="checkDisabledConditioned"
    />

    <vs-input
      v-else-if="usePercent"
      ref="percent"
      class="w-full"
      :label="fieldName"
      type="text"
      v-model.lazy="aliasPercent"
      v-money="percent"
      :name="fname"
      v-validate="validationRules"
      :danger="hasError"
      :danger-text="errorText"
      :min="0"
      :success="isSuccess"
      autocomplete="off"
      icon-pack="feather"
      v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
      @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
      :icon="inputIcon"
      :description-text="fieldDescription"
      :placeholder="placeholder"
      :disabled="checkDisabledConditioned"
    />

    <!-- Campos tipo textarea -->
    <vx-textarea class="w-full" 
      v-else-if="useTextarea"
      :label="fieldName"
      :type="fieldType" 
      v-model.lazy="dataContainer[fname]"
      :name="fname"
      v-validate="validationRules"
      :danger="hasError"
      :danger-text="errorText"
      :height="textareaH"
      :success="isSuccess"
      v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
      @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
      :description-text="fieldDescription"
      :placeholder="placeholder"
      :disabled="checkDisabledConditioned"
      />

    <!-- Campos tipo checkbox -->
    <vs-checkbox 
      v-else-if="useCheckbox"
      v-validate="validationRules"
      :name="fname"
      @change="onFieldChange()"
      v-model.lazy="dataContainer[fname]"
      :disabled="checkDisabledConditioned">{{fieldName}}
    </vs-checkbox>

    <template v-else-if="useSelect">
      <label for="" class="vs-input--label">{{ fieldName }}</label>
      <v-select
        :name="fname"
        class="vs-custom"
        :class="{'has-error': hasError, 'is-success': isSuccess}"
        v-model.lazy="dataContainer[fname]"
        v-validate="validationRules"
        :options="getOptionsForSelect"
        :reduce="item => item.value"
        :clearable="false"
        :searchable="useAutocomplete"
        :placeholder="getOptionsForSelect.length < 1 ? noDataPlaceholder : placeholder"
        :disabled="checkDisabledConditioned || getOptionsForSelect.length < 1"
        @input="onFieldChange()"
      >
      <div slot="no-options">No hay coincidencias</div>
      </v-select>
      <v-error v-if="hasError" :error="errorText" />
    </template>

    <!-- Campos tipo flag -->
    <template v-else-if="useFlagSelect">
      <label for="" class="vs-input--label">{{fieldName}}</label>
      <div class="vx-row m-0">
        <vue-country-code
          :name="fname"
          class="sm:w-3/12 pl-1"
          :class="{'has-error': hasError, 'is-success': isSuccess}"
          v-model.lazy="dataContainer['country_calling_code']"
          :placeholder="placeholder"
          :enableSearchField="true"
          searchPlaceholderText="Buscar código de país"
          :enabledCountryCode="true"
          :disabled="checkDisabledConditioned"
          @onSelect="onSelect"
          @change="onFieldChange()"
          :defaultCountry="preferredCountries"
          :preferredCountries="['MX']">
        </vue-country-code> 
        <div class="sm:w-9/12" v-if="f.field_type == 'tel'">
          <vs-input
            id="test-phone"
            class="w-full"
            :type="fieldType"
            v-model.lazy="dataContainer[fname]"
            :name="fname"
            v-validate="validationRules"
            :danger="hasError"
            :danger-text="errorText"
            :success="isSuccess"
            icon-pack="feather"
            v-on="f.caps ? {input: e => {dataContainer[fname] = upper(e), onFieldChange()}} : (f.on_change_method != null ? onFieldChange() : {})"
            @keydown.space="(event) => { if(f.trim) {event.preventDefault()}}"
            :icon="inputIcon"
            :description-text="fieldDescription"
            :placeholder="fieldPlaceholder(f)"
            :disabled="checkDisabledConditioned"          
          />
          <template slot="append">
            <div class="append-text border-success" style="height:40px">
              <span class="text-success"></span>
            </div>
          </template>     
        </div>
      </div>
    </template>

    <!-- Campos tipo date -->
    <datepicker class="w-full"
      v-else-if="useDatepicker && !useDateInput"                         
      v-model.lazy="dataContainer[fname]"
      :name="fname"
      :language="es"
      v-validate="validationRules"
      :danger="hasError"
      :danger-text="errorText"
      :success="isSuccess"
      :description-text="fieldDescription"
      :placeholder="placeholder"
      :disabled="checkDisabledConditioned"
      format="dd/MM/yyyy"
      initial-view="year"
      :use-utc="true"
      :disabledDates="disabledDates"
      @change="onFieldChange()" />
      <v-error v-if="useDatepicker && hasError" :error="errorText" />
      <vs-input
        v-else-if="useDateInput"
        type="date"
        class="w-full"
        :name="fname"
        :language="es"
        v-model.lazy="dataContainer[fname]"
        v-validate="validationRules"
        :danger="hasError"
        :danger-text="errorText"
        :success="isSuccess"
        @change="onFieldChange()"
        format="dd/MM/yyyy"
        :max="disabledDates"/>
      <v-error v-if="useDateInput && hasError" :error="errorText" />

          <!-- tooltip general -->
    <div v-if="f.indication != null && f.field_type != 'checkbox'" style="width:100%; display:flex" class="mt-1">
      <small>{{ f.indication }}</small>
      <!-- <label for="" class="vs-input--label">{{fieldName}}</label>
      <vx-tooltip
        :title="f.field_name"
        color="primary"
        position="left"
        class="tooltip-align"
        :text="f.indication">
        <vs-chip class="question-mark" size="small" color="primary">?</vs-chip>
      </vx-tooltip> -->
    </div>

    <!-- tooltip general -->
    <template v-else-if="f.indication != null && f.field_type == 'checkbox'" class="mt-1">
      <small>{{ f.indication }}</small>
      <!-- <vx-tooltip
        :title="f.field_name"
        color="primary"
        position="left"
        class="tooltip-align"
        :text="f.indication">
        <vs-chip class="question-mark" size="small" color="primary">?</vs-chip>
      </vx-tooltip> -->
    </template>

  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import formFieldsHelper from "@mixins/formFieldsHelper";
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import {VMoney} from 'v-money'

const generalColClass = "vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full mb-5";
const generalColLargeClass = "vx-col lg:w-1/2 md:w-1/2 sm:w-full w-full mb-5";
const textareaColClass = "vx-col lg:w-2/3 md:w-2/3 sm:w-full w-full mb-5";
const textareaExtendedColClass = "vx-col w-full mb-5";
const checkboxColClass = "vx-col w-full mb-5";
const noDataPlaceholderDef = "No hay opciones disponibles";
export default {
  name: "form-field",
  mixins: [formFieldsHelper, inputHelper, dateHelper],
  components: {
    Datepicker
  },
  props: {
    f: { type: Object, required: true },
    dataContainer: { type: Object, required: true, default: null },
    collectionsRoot: { type: Object },
    onChangeReceptor: { type: Function, required: true },
    evaluator: { type: Function, required: true },
    scope: { type: String },
    keepDisabled: { type: Boolean },
    blockedByDocument: { type: Boolean, default: false },
    hasBackofficeAccess: {type: Boolean, default: false},
    inputClassLarge: {type: Boolean, default: false},
    editableByBackoffice: {type: Boolean, default: false},
    useDateInput: {type: Boolean, default: false},
    countriesList : { type: Array, required: false, default() { return [] }}
  },
  directives: {money: VMoney},
  data() {
    return {
      es: es,
      specialError: null,

      fname: null,
      fieldName: null,
      rawFieldName: null,
      fieldDescription: null,
      fieldType: null,
      colClass: null,
      validationRules: null,
      inputIcon: null,
      accessControl: null,
      textareaH: null,
      editDisabled: null,

      noDataPlaceholder: noDataPlaceholderDef,

      aliasCurrency: null,
      useAliasCurrency: false,

      aliasPercent: null,
      useAliasPercent: false,
      watchAlias: false,

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      percent: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      preferredCountries: ''
    }
  },
  beforeMount(){
    this.setConfigs();
    if(this.f.exact_value != null && (this.dataContainer[this.fname] == null || this.dataContainer[this.fname] == "")){
      if(this.f.field_type == "select"){
        this.dataContainer[this.fname] = parseInt(this.f.exact_value);
      }
      else {
        this.dataContainer[this.fname] = this.f.exact_value;
      }
    }
    this.getDialCode();
  },
  async mounted(){
    if(this.f.on_mounted != null && this.onChangeReceptor != null){
      this.onChangeReceptor(this.f.on_mounted); // actualizar revisiones
    }
  },
  computed: {
    hasError(){
      return this.errors.has(this.fname, this.scope) || this.specialError != null;
    },
    errorText(){
      return this.errors.first(this.fname, this.scope) || this.specialError;
    },
    getValidatorField(){
      let ff = null;
      if(this.scope != null){
        let sc = this.fields["$" + this.scope];
        if(sc != null){
          ff = sc[this.f.fname];
        }
      }
      else {
        ff = this.fields[this.f.fname];
      }
      return ff;
    },
    placeholder(){
      if(this.f.disabled || this.f.is_computed || this.keepDisabled || this.f.is_readonly || this.blockedByDocument){
        return this.f.placeholder || "No definido";
      }
      else {
        return this.fieldPlaceholder(this.f);
      }
    },
    disabledDates() {
      if(this.f.disabled_from_today){
        return { from: new Date() };
      } else {
        return {};
      }
    },
    isSuccess(){
      let ff = this.getValidatorField;
      return (ff && ff.required && ff.valid && this.specialError == null);
    },
    useInputGroup(){
      return (this.f.input_group != null);
    },
    useInput(){
      return (this.f.field_type != 'textarea' && this.f.field_type != 'select' && this.f.field_type != 'date'
      && this.f.field_type != 'checkbox' && this.f.field_type != 'currency' && this.f.field_type != 'percent' 
      && this.f.field_type != 'flag-select' && this.f.field_type != 'tel');
    },
    useCurrency(){
      return (this.f.field_type == 'currency');
    },
    usePercent(){
      return (this.f.field_type == 'percent');
    },
    useTextarea(){
      return (this.f.field_type == 'textarea');
    },
    useSelect(){
      return (this.f.field_type == 'select');
    },
    useAutocomplete(){
      return this.getOptionsForSelect.length > 10;
    },
    useDatepicker(){
      return (this.f.field_type == 'date');
    },
    useCheckbox(){
      return (this.f.field_type == 'checkbox');
    },
    useFlagSelect() {
      return (this.f.field_type == 'tel');
    },
    checkShowConditioned(){
      return (!this.f.show_conditioned || 
        (this.f.show_conditioned && this.f.condition != null && this.evaluator(this.f.condition) == true));
    },
    checkDisabledConditioned(){
      if(this.f.disabled || this.keepDisabled || this.f.is_readonly || this.blockedByDocument || this.editDisabled){
        return true;
      }
      else {
        return (this.f.disabled_conditioned && 
        this.f.disabled_condition != null && this.evaluator(this.f.disabled_condition) == true);
      }      
    },
    inputGroupValue(){
      return null;
    },
    getOptionsForSelect(){
      let options = [];
      if(this.f.collection != null){
        let def = JSON.parse(this.f.collection);
        if(def.source == "self_options" ){
          def.options.forEach(opt => {
            options.push({value: parseInt(opt.k), label: opt.v}); //conversion a sintaxis de vx-select 
          });
        }
        else if(def.source == "object"){
          if(this.$props.collectionsRoot != null){
            let obj = this.$props.collectionsRoot[def.object_name];
            if(obj != null){
              obj.forEach(opt => {
                options.push({value: parseInt(opt[def.object_key]), label: opt[def.object_val]}); //conversion a sintaxis de vx-select 
              })
            }
            else {
              this.warn(`COLLECTION OBJECT MISSING [${this.f.fname}]: ${def.object_name}`);
            }
          }
          else {
            this.warn(`COLLECTION PARENT MISSING [${this.f.fname}]`);
          }
        }
      }
      return options;
    },
  },
  watch: {
    aliasCurrency: function(nval, oldval){
      if(this.watchAlias){
        this.dataContainer[this.fname] = this.unCurrencyFormat(nval);
      }
    },
    aliasPercent: function(nval, oldval){
      if(this.watchAlias){
        this.dataContainer[this.fname] = this.unPercentFormat(nval);
      }
    }
  },
  methods: {
    getDialCode(){
      if(this.fname == "phone"){
        let dialCode = this.dataContainer["country_calling_code"];
        if(dialCode){
          let country = this.countriesList.find(x => x.calling_code == dialCode);
          if(country != undefined){
            this.preferredCountries = country.iso_3166_2;
          }
        }
      }
    },
    onSelect({ dialCode }) {
      this.dataContainer['country_calling_code'] = `+${dialCode}`;
    },
    onFieldChange(){
      if(this.f.on_change_method != null && this.onChangeReceptor != null){
        this.onChangeReceptor(this.f.on_change_method);
      }
    },
    async validate(){
      this.specialError = null;
      if(this.f.field_type == 'checkbox'){
        // checkbox puede ser true o false
        if(this.dataContainer[this.fname] == null){
          this.dataContainer[this.fname] = false; // definir el valor con falso
        }
        return true; 
      } else if(this.f.field_type == 'flag-select') {
        return true;
      }
      let r = await this.$validator.validate();
      return r == true;
    },
    async silentValidate(){
      if(this.f.field_type == 'checkbox' || this.f.field_type == 'flag-select'){
        return true;        
      }
      let opts = {slient: true};
      let r = await this.$validator.validate(opts);
      return r == true;
    },
    isDirty(){
      let ff = this.getValidatorField;
      return (ff && ff.dirty);      
    },
    async checkForValidDirty(){
      if(this.f.exact_value || this.f.is_readonly){
        return {valid: true, dirty: true, db: this.f.db_table, fname: this.fname };
      }

      if(this.hasBackofficeAccess && this.f.bo_private != 1 && !this.$props.editableByBackoffice){
        return {valid: true, dirty: false, db: "", fname: "" };
      }

      let valid = await this.validate();

      if((this.f.is_computed && this.f.is_readonly) || (this.f.is_computed && this.f.disabled) ){
        return {valid: valid, dirty: true, db: this.f.db_table, fname: this.fname };
      }
      let res = {valid: valid, dirty: this.isDirty(), db: this.f.db_table, fname: this.fname };
      if(this.f.fname == "country_calling_code"){
        res = {valid: valid, dirty: true, db: this.f.db_table, fname: this.fname };
      }
      return res;
    },
    checkBackofficePermit(){
       if(this.hasBackofficeAccess){
        return this.f.bo_private == 1;
      }
      return true;
    },
    setError(mssg){
      this.specialError = mssg;
    },
    specialValidation(){      
      return this.f.special_validation;
    },
    setConfigs(){
      if(!this.$props.dataContainer){
        this.warn("Null container for " + this.f.fname);
      }
      this.fname = this.f.fname;
      this.fieldName = this.f.field_name + (this.f.is_required ? "" : "");
      this.rawFieldName = this.f.field_name;
      this.fieldDescription = null;
      this.fieldType = this.f.field_type == "currency" ? "text" : this.f.field_type;
      this.validationRules = this.fieldValidators(this.f);

      if(this.f.bo_private == true){
        this.accessControl = this.$props.hasBackofficeAccess == true;
      }
      else{
        this.accessControl = true;
        if(this.$props.hasBackofficeAccess == true && this.$props.editableByBackoffice == false){
          this.editDisabled = true;
        }
      }
      
      if(this.f.textarea_extended && this.f.textarea_extended == true){
        this.colClass = textareaExtendedColClass;
        this.textareaH = "200px";
      }
      else if(this.f.div_class != null){
        this.colClass = this.f.div_class;
      }
      else {
        if(this.f.field_type == 'checkbox'){
          this.colClass = checkboxColClass;
        }
        else if(this.f.field_type == 'textarea'){
          this.colClass = textareaColClass;
        }
        else if(this.f.field_type == 'flag-select'){
          this.colClass = "";
        } else {
          this.colClass = this.$props.inputClassLarge ? generalColLargeClass : generalColClass;
        }
      }

      if(this.f.field_type == 'email'){
        this.inputIcon = 'icon-mail';
      }
      else if(this.f.field_type == 'tel'){
        this.inputIcon = 'icon-phone';
      }
      else if(this.f.field_type == 'currency'){
        this.inputIcon = 'icon-dollar-sign';
        this.aliasCurrency = this.currencyFormat(this.dataContainer[this.fname]);
        this.useAliasCurrency = true;
        this.watchAlias = !this.f.is_computed;
      }
      else if(this.f.field_type == 'percent'){
        this.inputIcon = 'icon-percent';
        this.aliasPercent = this.percentFormat(this.dataContainer[this.fname]);
        this.useAliasPercent = true;
        this.watchAlias = !this.f.is_computed;
      }
    },
    updateComputed(val){
      if(this.useAliasCurrency){
        this.$refs.currency.$el.getElementsByTagName('input')[0].value = this.currencyFormat(val);
      }
      else if(this.useAliasPercent){
        this.$refs.percent.$el.getElementsByTagName('input')[0].value = this.percentFormat(val);
      }
      this.dataContainer[this.fname] = val;
    },
    currencyFormat(value, decimales = 2){
      if(!value) return 0;
      else return parseFloat(value).toFixed(decimales);
    },
    unCurrencyFormat(value) {
      if(!value) return 0;
      let str = value.toString();
      let val = str.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      return parseFloat(val.toString());
    },
    percentFormat(value, decimales = 2){
      if(!value) return 0;
      else return (parseFloat(value) * 100).toFixed(decimales);
    },
    unPercentFormat(value) {
      if(!value) return 0;
      let val = value.replace(",", "");
      return parseFloat(val.toString() / 100);
    },
  },
}
</script>

<style>
/* .tooltip-align {
  cursor: pointer;
  margin-left: .5rem;
  margin-top: -4px;
}

.question-mark {
  padding-right: 5px !important;
}

div.question-mark > span.vs-chip--text {
  margin-left: 3px !important;
}

.con-vs-chip {
  min-height: 20px;
  min-width: 20px;
  font-size: .8rem;
} */
</style>